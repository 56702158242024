// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-van-cart-js": () => import("./../../../src/pages/art-van-cart.js" /* webpackChunkName: "component---src-pages-art-van-cart-js" */),
  "component---src-pages-art-van-checkout-js": () => import("./../../../src/pages/art-van-checkout.js" /* webpackChunkName: "component---src-pages-art-van-checkout-js" */),
  "component---src-pages-email-templates-js": () => import("./../../../src/pages/email-templates.js" /* webpackChunkName: "component---src-pages-email-templates-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lake-huron-lodge-js": () => import("./../../../src/pages/lake-huron-lodge.js" /* webpackChunkName: "component---src-pages-lake-huron-lodge-js" */),
  "component---src-pages-my-sofa-custom-sofa-builder-js": () => import("./../../../src/pages/my-sofa-custom-sofa-builder.js" /* webpackChunkName: "component---src-pages-my-sofa-custom-sofa-builder-js" */),
  "component---src-pages-puresleep-homepage-js": () => import("./../../../src/pages/puresleep-homepage.js" /* webpackChunkName: "component---src-pages-puresleep-homepage-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */)
}

